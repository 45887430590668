import React,{useState} from 'react'
import { GrClose } from 'react-icons/gr';

import Footer from './Footer'
import Header from './Header'
import {message} from '../../data/graduationNotice'

const Layout = ({children}) => {
  const [togle, setTogle] = useState(true);
  const showToggle =()=>{
    setTogle(!togle);
    // setSubTogle(!subTogle);
  }
  return (
    <>
    
    {/* <div className={`bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-[70%] mx-[300px] sm:mx-5 xs:mx-5 z-50  my-[80px] fixed  ${togle ? "" : "hidden"}`} role="alert">
  <div className="flex flex-col ">
    <div className="py-1 my-2 flex flex-row justify-around">
      <svg className="fill-current h-8 w-8 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
      <p className="font-bold my-2 text-2xl sm:text-xl xs:text-base">{message.title}</p>
      <GrClose className='text-3xl cursor-pointer ' onClick={showToggle} />
      </div>
      <hr />
    <div>
      
      
      <p className="text-base p-2 whitespace-pre-line xs:hidden">{message.body}</p>
     
     
    </div>
  </div>
</div> */}
 
        <Header/>
        <div>
            {children}
        </div>
        <Footer/>
    </>
  )
}

export default Layout