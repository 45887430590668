import React, {useEffect} from 'react'
import Details from "./components/pages/Details";
import AcademicProgram from "./components/pages/academics/academicProgram";
import Undergraduate from "./components/pages/academics/undergraduate";

import PostgradHome from "./components/pages/postgrad/home";
import PostgradDetail from "./components/pages/postgrad/detail";
import Program from "./components/pages/academics/program";
import CertificateVerification from "./components/pages/certificateVerification";
import Callender from "./components/pages/callender";
import Careers from "./components/pages/careers/careers";
import Bucontacts from "./components/pages/contacts";
import SingleCareer from "./components/pages/careers/singleCareer";

import AllStaff from "./components/pages/staff/allStaff";

import Erusmas from "./components/pages/erusmus/index";
import ErusmasLink from "./components/pages/erusmus/erusmaslink";
// import ErusmasStaff from "./components/pages/erusmus/erasmusStaff";
import ErasmusLayout from "./components/pages/erusmus/erasmus_layout";

import FormsList from "./components/pages/forms/list";
import Convacation from "./components/pages/convacation/convacation";

import { Route, Routes,useLocation } from "react-router-dom";
import Landpage from "./components/landpage";
import NewsDetails from "./dashboard/pages/NewsDetails";
import News from "./components/pages/news";
import EventDetails from "./components/pages/events/eventDetails";

//pagelinks
// import Welcome from "./components/pages/pageLinks/overview";
import Brochure from "./components/pages/brochure/brochure";

//auth
import Login from "./components/pages/auth/Login";
import ForgotPassword from "./components/pages/auth/forgotpassword";
import ResetPassword from "./components/pages/auth/resetPassword";
import Revorymessage from "./components/pages/auth/revorymessage";
import Register from "./components/pages/auth/register";
// ProtectedRoutes
import ProtectedRoutes from "./protectedRoutes";

//layout
import Layout from "./components/sections/Layout";
// inner layout for side bar links or redirects.
// import Dashborad from "./dashboard/Dashborad";
import Dashborad from "./dashboard/Dashborad";
import MyNews from "./dashboard/main/news/News";
import Activity from "./dashboard/main/activities/Activities";
import NewsEntery from "./dashboard/main/news/newsEntery";
import ActivityEntery from "./dashboard/main/activities/activityEntery";
import EditNews from "./dashboard/main/news/editNews";
import EditActivity from "./dashboard/main/activities/editActivity";
import UsersList from "./dashboard/main/users/listUsers";
import Schools from "./dashboard/main/academics/schools";
import NewSchool from "./dashboard/main/academics/newschool";

import MyStaffList from "./dashboard/main/staff/staffList";
import CreateStaff from "./dashboard/main/staff/createStaff";
import EditStaff from "./dashboard/main/staff/editStaff";

import CreateUserPortal from "./dashboard/main/portal/createUserPortal";
import UpdateUserPortal from "./dashboard/main/portal/updateUserPortal";

import UpdatePublication from "./dashboard/main/staff/UpdatePublication";
import CreateDepts from "./dashboard/main/staff/departments";
import EmployStaff from "./dashboard/main/staff/employStaff";

import UsersPortalList from "./dashboard/main/portal/usersList";

import Sidebar from "./dashboard/sidebar/Side";
import Main from "./dashboard/main/Main";
import LocalLayouts from "./dashboard/Layout/Layout";

//admision
import Accreditations from "./components/pages/accreditations";
//admision
import Admision from "./components/pages/admission/admission";
import AdmissonNotice from "./components/pages/admission/admissonNotice";
import AdmissionContacts from "./components/pages/admission/contact";
import Eligibiligy from "./components/pages/admission/eligibility";
import Documents from "./components/pages/admission/documents";
import AdmissionTest from "./components/pages/admission/adminssionTest";
//innovation
import Innovation from "./components/pages/invotation";
//partners
import Organizational from "./components/pages/partners/organizations";
import Univeristy from "./components/pages/partners/university";
import Local from "./components/pages/partners/local";
import PartnerDetails from "./components/pages/partners/partnerDetails";
//About university
// import AboutUs from "./components/pages/aboutus";
import AboutUs from "./components/pages/about/aboutus";
import Welcome from "./components/pages/about/welcome";
import Impact from "./components/pages/about/impact";
import Diversity from "./components/pages/about/diversity";
import Counceling from "./components/pages/about/counceling";
import Leadersips from "./components/pages/about/leadership";
import Bio from "./components/pages/about/bio";
import DBio from "./components/pages/about/dbiography";
import LeaderNav from "./components/pages/about/leaderNav";
import Messages from "./components/pages/about/messages";
import Inauguration from "./components/pages/about/Inauguration";
import Campus from "./components/pages/about/campus";
import Scholarships from "./components/pages/about/scholarship";

//commitees
import Commitees from "./components/pages/committees/index";
//StaffList
import StaffList from "./components/pages/staff/saffList";
// SingleStaff
import SingleStaff from "./components/pages/staff/singleStaff";
import StaffDetail from "./components/pages/staff/staffDetail";
// {acamdemic program }
import ProgramDetails from "./components/pages/academics/programDetail";
import About from "./components/pages/academics/about";
import Departments from "./components/pages/academics/departments";
import Fees from "./components/pages/academics/fees";
import FacultyActivity from "./components/pages/academics/FacultyActivity";
import Requirements from "./components/pages/academics/requirements";
import ActivityDetail from "./components/pages/academics/activityDetail";

// {acamdemic program }
import Research from "./components/pages/research/research";

import Publications from "./components/pages/research/publications";
import Conferences from "./components/pages/research/conference";
import Journals from "./components/pages/research/journals";
import ResearchAllience from "./components/pages/research/researchallience";
import Dashboard from "./dashboard/Dashborad";
import Advisors from "./components/pages/advisors/advisor";
import Events from "./components/pages/events/events";
import FacultyAlumni from "./components/pages/academics/alumni";



//foundation year/
import FoundationPage from "./components/pages/foundation/foundation";
import AboutFoundation from "./components/pages/foundation/about";
import DeanMessageFoundation from "./components/pages/foundation/deanMessage";
import CommitteeFoundation from "./components/pages/foundation/committee";
import CoursesFoundation from "./components/pages/foundation/courses";
import AccordianFoundation from "./components/pages/foundation/accordian";
import StaffFoundation from "./components/pages/foundation/staff";

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-8TWW15DG2E', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
};



function App() {
  useEffect(() => {
    // Load gtag.js script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-8TWW15DG2E`;
    script.async = true;
    document.head.appendChild(script);
  
    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-8TWW15DG2E');
  }, []);

  useGoogleAnalytics()
  return (
    <div className="">
      <Routes>
        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        {/* for all visitors */}
        <Route
          path="/"
          element={
            <Layout>
              {" "}
              <Landpage />{" "}
            </Layout>
          }
          exac
        />
        {/* this navigation is for page links */}
        <Route
          path="/about/welcome"
          element={
            <Layout>
              <AboutUs>
                <Welcome />
              </AboutUs>
            </Layout>
          }
          exac
        />
        <Route
          path="/about/impact"
          element={
            <Layout>
              <AboutUs>
                <Impact />
              </AboutUs>
            </Layout>
          }
          exac
        />
        <Route
          path="/about/diversity"
          element={
            <Layout>
              <AboutUs>
                <Diversity />
              </AboutUs>
            </Layout>
          }
          exac
        />
        <Route
          path="/about/counseling"
          element={
            <Layout>
              <AboutUs>
                <Counceling />
              </AboutUs>
            </Layout>
          }
          exac
        />
         <Route
          path="/about/leadership"
          element={
            <Layout>
              <AboutUs>
                <Leadersips />
              </AboutUs>
            </Layout>
          }
          exac
        />
         <Route
          path="/about/leadership/biography"
          element={
            <Layout>
              <AboutUs>
                <LeaderNav>
                  <Bio />
                </LeaderNav>
              </AboutUs>
            </Layout>
          }
          exac
        />
        <Route
          path="/about/leadership/dbiography"
          element={
            <Layout>
              <AboutUs>
                  <DBio />
              </AboutUs>
            </Layout>
          }
          exac
        />
        <Route
          path="/about/leadership/messages"
          element={
            <Layout>
              <AboutUs>
                <LeaderNav>
                  <Messages />
                </LeaderNav>
              </AboutUs>
            </Layout>
          }
          exac
        />
        <Route
          path="/about/leadership/inauguration"
          element={
            <Layout>
              <AboutUs>
                <LeaderNav>
                  <Inauguration />
                </LeaderNav>
              </AboutUs>
            </Layout>
          }
          exac
        />
         <Route
          path="/about/campus"
          element={
            <Layout>
              <AboutUs>
                <Campus />
              </AboutUs>
            </Layout>
          }
          exac
        />
         <Route
          path="/about/scholarships"
          element={
            <Layout>
              <AboutUs>
                <Scholarships />
              </AboutUs>
            </Layout>
          }
          exac
        />

        {/* accreditations and partnerships */}
        <Route
          path="/accreditations"
          element={
            <Layout>
                <Accreditations />
            </Layout>
          }
          exac
        />

        {/* this navigation is for brochure documents */}
        <Route
          path="/brochure"
          element={
            <Layout>
              {" "}
              <Brochure />{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/details"
          element={
            <Layout>
              {" "}
              <Details />{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/events/:slug"
          element={
            <Layout>
              {" "}
              <EventDetails />{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/academics"
          element={
            <Layout>
              {" "}
              <AcademicProgram />{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/innovation"
          element={
            <Layout>
              {" "}
              <Innovation />
            </Layout>
          }
          exac
        />
        <Route
          path="/advisors"
          element={
            <Layout>
              {" "}
              <Advisors />
            </Layout>
          }
          exac
        />
        <Route
          path="/commitees"
          element={
            <Layout>
              {" "}
              <Commitees />
            </Layout>
          }
          exac
        />
        {/* partners section */}
        <Route
          path="/university-partners"
          element={
            <Layout>
              {" "}
              <Univeristy />
            </Layout>
          }
          exac
        />
        <Route
          path="/local-partners"
          element={
            <Layout>
              {" "}
              <Local />
            </Layout>
          }
          exac
        />
        <Route
          path="/organizational-partners"
          element={
            <Layout>
              {" "}
              <Organizational />
            </Layout>
          }
          exac
        />
        <Route
          path="/:slug/details"
          element={
            <Layout>
              <PartnerDetails />
            </Layout>
          }
          exac
        />
        <Route
          path="/events"
          element={
            <Layout>
              {" "}
              <Events />
            </Layout>
          }
          exac
        />
        <Route
          path="/staff"
          element={
            <Layout>
              {" "}
              <AllStaff />
            </Layout>
          }
          exac
        />
        <Route
          path="/staff/:staffId"
          element={
            <Layout>
              {" "}
              <StaffDetail />
            </Layout>
          }
          exac
        />
        <Route
          path="/erasmus"
          element={
            <ErasmusLayout>
              <Erusmas />
            </ErasmusLayout>
          }
          exac
        />
        {/* <Route
          path="/erasmus/staff"
          element={
            <ErasmusLayout>
              <ErusmasStaff />
            </ErasmusLayout>
          }
          exac
        /> */}
        <Route
          path="/erasmus/:id"
          element={
            <ErasmusLayout>
              <ErusmasLink />
            </ErasmusLayout>
          }
          exac
        />
        {/* main routes */}
        <Route
          path="/about/welcome"
          element={
            <Layout>
              <AboutUs>
                <Welcome/>
              </AboutUs>
            </Layout>
          }
          exac
        />
        <Route
          path="/forms"
          element={
            <Layout>
              {" "}
              <FormsList />{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/convacation"
          element={
            <Layout>
              <Convacation />{" "}
            </Layout>
          }
          exac
        />
        {/* admission sub naviagation */}
        <Route
          path="/admision"
          element={
            <Layout>
              {" "}
              <Admision>
                <AdmissonNotice />
              </Admision>{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/admision/admissioncontacts"
          element={
            <Layout>
              {" "}
              <Admision>
                <AdmissionContacts />
              </Admision>{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/admision/admisionnotice"
          element={
            <Layout>
              {" "}
              <Admision>
                <AdmissonNotice />
              </Admision>{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/admision/eligibility"
          element={
            <Layout>
              {" "}
              <Admision>
                <Eligibiligy />
              </Admision>{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/admision/checklists"
          element={
            <Layout>
              {" "}
              <Admision>
                <Documents />
              </Admision>{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/admision/result"
          element={
            <Layout>
              {" "}
              <Admision>
                <AdmissionTest />
              </Admision>{" "}
            </Layout>
          }
          exac
        />
        {/* academics sub navigation */}
        <Route
          path="/academics/foundation/committee"
          element={
            <Layout>
              <FoundationPage>
              <CommitteeFoundation />
              </FoundationPage>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/foundation/about"
          element={
            <Layout>
              {" "}
              <FoundationPage>
              <AboutFoundation />{" "}
              </FoundationPage>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/foundation/dean-message"
          element={
            <Layout>
              {" "}
              <FoundationPage>
              <DeanMessageFoundation />{" "}
              </FoundationPage>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/foundation/courses"
          element={
            <Layout>
              {" "}
              <FoundationPage>
              <AccordianFoundation />{" "}
              </FoundationPage>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/foundation/allstaff"
          element={
            <Layout>
              {" "}
              <FoundationPage>
              <StaffFoundation />{" "}
              </FoundationPage>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate"
          element={
            <Layout>
              {" "}
              <Undergraduate />{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/postgraduate"
          element={
            <Layout>
              {" "}
              <PostgradHome />{" "}
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/postgraduate/:tag"
          element={
            <Layout>
              {" "}
              <PostgradDetail />{" "}
            </Layout>
          }
          exac
        />
        {/* academics sub navigation with program wrap */}
        <Route
          path="/academics/undergraduate/:slug/dean-message"
          element={
            <Layout>
              <Program>
                <ProgramDetails />
              </Program>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate/:slug/about"
          element={
            <Layout>
              <Program>
                <About />
              </Program>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate/:slug/requirements"
          element={
            <Layout>
              <Program>
                <Requirements />
              </Program>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate/:slug/departments"
          element={
            <Layout>
              {" "}
              <Program>
                <Departments />
              </Program>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate/:slug/allstaff"
          element={
            <Layout>
              <Program>
                <StaffList />
              </Program>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate/:slug/allstaff/:id"
          element={
            <Layout>
              <Program>
                <SingleStaff />
              </Program>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate/:slug/fees"
          element={
            <Layout>
              <Program>
                <Fees />
              </Program>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate/:slug/activities"
          element={
            <Layout>
              <Program>
                <FacultyActivity />
              </Program>
            </Layout>
          }
          exac
        />
         <Route
          path="/academics/undergraduate/:slug/activities/:_id"
          element={
            <Layout>
              <Program>
                <ActivityDetail />
              </Program>
            </Layout>
          }
          exac
        />
        <Route
          path="/academics/undergraduate/:slug/alumni"
          element={
            <Layout>
              <Program>
                <FacultyAlumni />
              </Program>
            </Layout>
          }
          exac
        />
        {/* research sub navagations */}\
        <Route
          path="/research/publications"
          element={
            <Layout>
              <Research>
                <Publications />
              </Research>
            </Layout>
          }
          exac
        />
        <Route
          path="/research/conference"
          element={
            <Layout>
              <Research>
                <Conferences />
              </Research>
            </Layout>
          }
          exac
        />
        <Route
          path="/research/journals"
          element={
            <Layout>
              <Research>
                <Journals />
              </Research>
            </Layout>
          }
          exac
        />
        <Route
          path="/research/researchallience"
          element={
            <Layout>
              <Research>
                <ResearchAllience />
              </Research>
            </Layout>
          }
          exac
        />
        {/* verrify */}
        <Route
          path="/verify"
          element={
            <Layout>
              <CertificateVerification />
            </Layout>
          }
          exac
        />
        {/* callender */}
        <Route
          path="/callender"
          element={
            <Layout>
              <Callender />
            </Layout>
          }
          exac
        />
        {/* careers */}
        <Route
          path="/careers"
          element={
            <Layout>
              <Careers />
            </Layout>
          }
          exac
        />
        {/* single careers */}
        <Route
          path="/careers/:careerid"
          element={
            <Layout>
              <SingleCareer />
            </Layout>
          }
          exac
        />
        {/* bu contacts */}
        <Route
          path="/bucontacts"
          element={
            <Layout>
              <Bucontacts />
            </Layout>
          }
          exac
        />
        {/* news details */}
        <Route
          path="/news/:slug"
          element={
            <Layout>
              <NewsDetails />
            </Layout>
          }
          exac
        />
        <Route
          path="/news"
          element={
            <Layout>
              <News />
            </Layout>
          }
          exac
        />
        {/* auth */}
        <Route path="/login" element={<Login />} exac />
        <Route path="/recovermessage" element={<Revorymessage />} exac />
        <Route path="/resetpassword" element={<ResetPassword />} exac />
        <Route path="/recoverpassword" element={<ForgotPassword />} exac />
        {/* proteced routes */}
        <Route element={<ProtectedRoutes />}>
          <Route
            path="/dashboard"
            element={
              <Dashboard>
                <LocalLayouts>
                  <Main />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/mynews"
            element={
              <Dashboard>
                <LocalLayouts>
                  <MyNews />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/myactivity"
            element={
              <Dashboard>
                <LocalLayouts>
                  <Activity />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/create-staff"
            element={
              <Dashboard>
                <LocalLayouts>
                  <CreateStaff />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/edit-staff"
            element={
              <Dashboard>
                <LocalLayouts>
                  <EditStaff />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/update-publication"
            element={
              <Dashboard>
                <LocalLayouts>
                  <UpdatePublication />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/staff-list"
            element={
              <Dashboard>
                <LocalLayouts>
                  <MyStaffList />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/create-depts"
            element={
              <Dashboard>
                <LocalLayouts>
                  <CreateDepts />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/employ-staff"
            element={
              <Dashboard>
                <LocalLayouts>
                  <EmployStaff />
                </LocalLayouts>
              </Dashboard>
            }
          />
          {/* student portal */}
          <Route
            path="/portal"
            element={
              <Dashboard>
                <LocalLayouts>
                  <UsersPortalList />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/create-user-portal"
            element={
              <Dashboard>
                <LocalLayouts>
                  <CreateUserPortal />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/edit-user-portal"
            element={
              <Dashboard>
                <LocalLayouts>
                  <UpdateUserPortal />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/newsEntery"
            element={
              <Dashboard>
                <LocalLayouts>
                  <NewsEntery />
                </LocalLayouts>
              </Dashboard>
            }
          />
           <Route
            path="/activityEntery"
            element={
              <Dashboard>
                <LocalLayouts>
                  <ActivityEntery />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/edit-news"
            element={
              <Dashboard>
                <LocalLayouts>
                  <EditNews />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/edit-activity"
            element={
              <Dashboard>
                <LocalLayouts>
                  <EditActivity />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/listUsers"
            element={
              <Dashboard>
                <LocalLayouts>
                  <UsersList />
                </LocalLayouts>
              </Dashboard>
            }
          />
          
          <Route
            path="/register"
            element={
              <Dashboard>
                <LocalLayouts>
                  <Register />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/schools"
            element={
              <Dashboard>
                <LocalLayouts>
                  <Schools />
                </LocalLayouts>
              </Dashboard>
            }
          />
          <Route
            path="/newschool"
            element={
              <Dashboard>
                <LocalLayouts>
                  <NewSchool />
                </LocalLayouts>
              </Dashboard>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
