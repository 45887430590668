export const medContacts = {
    email: "dean-medicine@bu.edu.so",
    phone1: "+252615800101", 
    phone2:"+252615237044", 
    phone3: "+252612541492",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F5 B-09.",
}

export const eduContacts = {
    email: "dean-education@bu.edu.so",
    phone1:"+252619279555",
    phone2:"+252618500030",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F5 B-02.",

}

export const csContacts = {
    email: "cit@bu.edu.so",
    phone1:"+252615678538",
    phone2:"+252615258212",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F5 B-07.",
}

export const engContacts = {
    email: "dean-engineering@bu.edu.so",
    phone1:"+25261-4775135",
    phone2:"1019 ",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F4 C-03.",
}

export const slContacts = {
    email: "busharialaw@gmail.com / dean-sharialaw@bu.edu.so / sharialaw@bu.edu.so",
    phone1:"+252-6-1-5839898",
    phone2:"+252615982061 ",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F5 B-08.",
}

export const agrContacts = {
    email: "Secretary-Agriculture@bu.edu.so / Dean-agriculture@bu.edu.so",
    phone1:"+252615727870",
    phone2:"",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F4 C-02.",
}

export const vetContacts = {
    email: "dean-veterinary@bu.edu.so",
    phone1:"+252 61 9443743",
    phone2:"+252615306580",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F4 C-01.",
}

export const hsContacts = {
    email: "dean-healthscience@bu.edu.so ",
    phone1:"+25261-5925640",
    phone2:"+252615306580 / 25261-5563369",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F5 B-04.",
}

export const ecoContacts = {
    email: "bufema2014@bu.edu.so / dean-fema@bu.edu.so",
    phone1:"+252618438870",
    phone2:":+252615666722 / +25261555921",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F4 C-04.",
}

export const geoContacts = {
    email: "dean-geoscience@bu.edu.so / secretary-geoscience@bu.edu.so",
    phone1:"+252617496642",
    phone2:"+252619120103",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F4 C-05.",
}

export const denContacts = {
    email: "dean-dentistry@bu.edu.so / secretary-dentistry@bu.edu.so",
    phone1:"+252615746060",
    phone2:"+252619619182 ",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F5 B-05.",
}


export const psContacts = {
    email: "dean-ss@bu.edu.so",
    phone1:"+252612339335",
    phone2:"+252616663055",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F4 C-06.",
}


export const msContacts = {
    email: "Dean-marincescience@bu.edu.so",
    phone1:"0615507711",
    phone2:"",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "F4 C-07.", 
}

export const fyContacts = {
    email: "dean-foundation@bu.edu.so",
    phone1:"+252615561224",
    phone2:"",
    location: "Prof. Ibrahim Addo Campus", 
    Office: "Behind the hall room.", 
}
