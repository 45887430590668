import React, { useState } from 'react'
import { HiMenuAlt3 } from 'react-icons/hi';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

import { Link } from 'react-router-dom';
import {menus} from "../../data/menus";

const logo = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/icon.png'
const logo_mobile = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/others/Benadir University Logo.png'


const Header = () => {
  // console.log(menus);
    const [search, setSearch] = useState(true);
    const [togle, setTogle] = useState(false);

  const show =()=>{
    setSearch(!search)
  }

  const showToggle =()=>{
    setTogle(!togle);
    // setSubTogle(!subTogle);
  }

  return (
   <>
      {/* header */}
      <div className='bg-[#0863aa] z-20 fixed w-full py-2'>
          {/* Top Search */}
      <div className={`w-full top-0 grid grid-cols-12 px-5 md:px-12 xl:px-12 py-4 gap-1 ${search ? "hidden" : ""}`}>
        <div className='col-span-10 w-full'>
        <input type='text' placeholder='Search Here' className='w-full py-3 px-3 outline-none rounded-md'/>
        </div>
     
      <div className='border border-white flex justify-center items-center rounded-md'>
      <BsSearch className='h-5 w-5 cursor-pointer text-white'/>
      </div>

      <div className=' flex justify-center items-center' onClick={show}>
      <AiOutlineClose className='h-5 w-5 cursor-pointer text-white'/>
      </div>
      </div>

      <div className='flex justify-between sm:px-5 xs:px-3 md:px-12 xl:px-12'>

        {/* left */}
          <Link to='/' className='flex items-center gap-x-4 text-white'> 
          <img src={logo} className="md:h-[80px] md:w-[278px] xl:h-[80px] xl:w-[278px] sm:hidden xs:hidden " alt="logo"/>
          <img src={logo_mobile} className="md:hidden xl:hidden sm:h-[80px] sm:w-[80px] xs:h-[60px] xs:w-[60px]" alt="logo"/>
          {/* <p className='font-semibold capitalize text-[20px] hidden md:hidden  xl:hidden xs:hidden'>benadir university</p> */}
          </Link>


        {/* right */}
        <div className='flex md:gap-x-8 xl:gap-x-8 items-center text-white'>


         {/* two */}
         <div className={`flex gap-2 cursor-pointer items-center hover:bg-white h-full hover:text-[#0802F3] -pointer flex-col md:flex-row xl:flex-row justify-center px-2 ${search ? "" : "hidden"}`}  onClick={show}>
          <p className='text-[15.5px]'>Search</p>
          <BsSearch className='h-5 w-5'/>
        </div>

         {/* three */}
         <div className='flex gap-2 items-center hover:bg-white h-full hover:text-[#0802F3] cursor-pointer flex-col md:flex-row xl:flex-row justify-center px-2'  onClick={showToggle}>
         <p className='text-[15.5px]'>Menu</p>
          <HiMenuAlt3 className='h-5 w-5'/>
        </div>
        </div>
      </div>
      </div>
      
    {/* side bar */}
    {/* <div className={`absolute z-10 right-[350px]   ${togle ? "hidden" : ""} ${search ? "top-20":"top-[9.5rem]"}`} > */}
    {/* <div className={`absolute z-50  overflow-x-hidden overflow-y-auto inset-y-0 right-0 w-[300px] xs:w-full outline-none focus:outline-none ${togle ? "" : "hidden"} ${search ? "xs:top-[75px] top-[95px]":"xs:top-[155px] top-[175px]"}`} > */}
    <div className={`fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-end items-start z-30 ease-in duration-300 ${togle ? "" : "hidden"} ${search ? "xs:top-[75px] top-[95px]":"xs:top-[155px] top-[175px]"}`} onClick={showToggle}>

    <div className='bg-[#EDF4F4] xs:w-full  w-[300px] max:w-[500px]  overflow-auto pt-4 fixed '>
      <div className='flex flex-col justify-center '>

          {/* main menu */}
        <div className='flex justify-between font-bold'>
        <p className='px-6  text-[18px] h-[3rem]'>Main menu</p>
            <AiOutlineClose className='h-5 w-5 cursor-pointer mr-5 ' onClick={showToggle}/>
        </div>

        {menus.map( m => (
      
          <div key={m.id} className='grid grid-cols-4 h-[3rem] items-center justify-center cursor-pointer border border-l-0 border-r-0'>
          <Link to={`/${m.link}`} className='col-span-4 whitespace-nowrap hover:bg-[#0863aa] h-full flex items-center justify-between text-black hover:text-white px-5 font-normal text-[18px] ml-6' onClick={showToggle}>
           {m.text}
          </Link>
        </div>
        ))}
      </div> 
      </div>

      </div>
    

      </>
  )
}

export default Header