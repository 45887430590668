import React from 'react'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

const PagesLinks = () => {

const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';

// const ir =  backet + '/others/khalif.jpg'
const welcome = backet + '/page-links/welcome.jpg'
// const agri = backet + '/others/agri.jpg'
const undergrad = backet + '/undergrad/undergrad.jpg'
const postgrad = backet + '/page-links/postgrad.jpg'



const latestNews = 
{id: 1, title: "Welcome to BU", image:welcome, date: "Dec 17, 2022", content: "Congratulations on your successful admission to Benadir University! The faculty and staff are excited to welcome you to our diverse academic community. We are confident that you will make a lasting impact on the institution and beyond." };
const newsDaat = [
{ id: 2, title: "Undergraduate", image:  undergrad, link: "undergraduate"},
{ id: 3, title: "Postgraduate", image:  postgrad, link: "postgraduate"},

]


  return (
      <div>
        {/* cards */}
        <div className='grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3  mx-4 md:mx-10 xl:mx-10 mb-5 gap-y-4 md:gap-6 xl:gap-6 justify-center items-center '>
        {/* card one */}
        <Link to="/about/welcome" className='bg-white rounded-lg shadow-lg hover:border-2 hover:border-[#000] cursor-pointer'>
       <div >
       <img src={latestNews.image} className='rounded-t-lg object-cover md:h-[220px] xl:h-[250px] w-full' alt=""/>

       <div className='px-6 mt-8 mb-10'>
       <p className='font-bold text-[16px] md:text-[1.5rem] xl:text-[1.5rem]'>{latestNews.title}</p>
       <p className='text-start text-[15px]'>{latestNews.content}</p>
       </div>
    
       </div>
        </Link>

        {/* card two */}
        <div className='col-span-1 xs:col-span-1 md:col-span-1 '>

          <div className='grid xs:grid-cols-1 grid-rows-2 gap-2 md:gap-6 xl:gap-6'> 

          {/* post one */}
        {
          newsDaat.map( n => (

         <div key={n.id} className='relative cursor-pointer border-[1px] hover:border-2 hover:border-[#000] rounded-lg h-[200px] mx-h-[200px]'>
          <Link to={`/academics/${n.link}`}>
         <img src={n.image} className='rounded-lg w-full object-cover h-full' alt=""/>
          <div className='bg-white rounded-b-lg absolute bottom-0 w-full opacity-90'>
                <p className='px-2 py-4 text-[20px] font-semibold'>{n.title}</p>
          </div>         
          </Link>
         </div>
          ))
        }
          </div>

        </div>
        <div className='col-span-1 md:col-span-1 xs:col-span-1  h-[430px] mx-h-[430px]'>
        <ReactPlayer
          url='https://www.youtube.com/watch?v=ClX_810xKX8'
          width='100%'
          height='100%'
        />

        </div>
      </div>
      </div>

    
  )
}

export default PagesLinks