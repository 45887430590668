import _ from "lodash"

import HM from "../assets/images/ourpartners/hu.png";
import MU from "../assets/images/ourpartners/mu.jpeg";
import a_u_w_cover from "../assets/images/ourpartners/a_u_w_cover.jpg";
import PRiME_Wordmark from "../assets/images/ourpartners/PRiME_Wordmark-PMS+655+C_next_gen-01.png";
import uom from "../assets/images/ourpartners/uom.png";
import uoj from "../assets/images/ourpartners/uoj.png";
import ki from "../assets/images/ourpartners/ki_sweeden.png";
import palermo from "../assets/images/ourpartners/palermo_university.png";
import IUA from "../assets/images/ourpartners/IUA.jpg";
import usim from "../assets/images/ourpartners/usim.png";
import cust from "../assets/images/ourpartners/Cairo_University_Crest.png";
import Benha from "../assets/images/ourpartners/Benha_University_Logo.png";
import ain from "../assets/images/ourpartners/Ain_Shams_logo.png";
import mmust from "../assets/images/ourpartners/mmust.jpg";
import bulent from "../assets/images/ourpartners/Bülent_Ecevit_University_Logo.png";
import cankiri from "../assets/images/ourpartners/cankiri.png";
import kenyata from "../assets/images/ourpartners/Kenyatta_University_logo.png";
import kazan from "../assets/images/ourpartners/kazan_university.png";
import au_egypt from "../assets/images/ourpartners/au_egypt.jpeg";
import meu from "../assets/images/ourpartners/meu.jpg";
import ninu from "../assets/images/ourpartners/ninu.jpeg";
import saglik from "../assets/images/ourpartners/saglik-bilimleri-logo-en.png";
import umea from "../assets/images/ourpartners/umea-university.png";
import sust from "../assets/images/ourpartners/sust.jpg";
import uppsala from "../assets/images/ourpartners/Uppsala_universitet_logo.jpg";
import uqu from "../assets/images/ourpartners/uqu.png";

import comsats from "../assets/images/ourpartners/comsats.jpg";
import isuislamabad from "../assets/images/ourpartners/isuislamabad.jpg";
import bahria from "../assets/images/ourpartners/bahria-universit.png";
import djabuti from "../assets/images/ourpartners/djabuti.jpg";
import JigJiga from "../assets/images/ourpartners/jigjiga univesity.jpg";
import genthernburg from "../assets/images/ourpartners/genthernburg.png";
import havana from "../assets/images/ourpartners/havana.png";
import Khartoum from "../assets/images/ourpartners/khartum.png";
import nairobi from "../assets/images/ourpartners/Uni-nairobi.jpg";
import arid from "../assets/images/ourpartners/arid-agriculture-university.png";
import agakhan from "../assets/images/ourpartners/agakhan.png";
import istanbul from "../assets/images/ourpartners/istanbul-medinia.jpg";
import OMU_logo from "../assets/images/ourpartners/omu-logo.jpg";

// organizational partners.
import comstech from "../assets/images/ourpartners/organizations/comstech.png"
import mgfoundation from "../assets/images/ourpartners/organizations/mgfoundation.png"
import usaid from "../assets/images/ourpartners/organizations/usaid.png"
import hormuudSalamFoundation from "../assets/images/ourpartners/organizations/hormuudSalamFoundation.png"
import shag from "../assets/images/ourpartners/organizations/shag.png"
import who from "../assets/images/ourpartners/organizations/who.png"
import iom from "../assets/images/ourpartners/organizations/iom.png"
import tika from "../assets/images/ourpartners/organizations/tika.png"
import turkish_embassy from "../assets/images/ourpartners/organizations/turkish-embassy.jpg"
import unicef from "../assets/images/ourpartners/organizations/unicef.png"

//local institutions.

import Abrar from "../assets/images/ourpartners/local/Abrar Univerity.jpg"
import Aden from "../assets/images/ourpartners/local/Aden Adde International University.jpg"
import Alarabiya from "../assets/images/ourpartners/local/Al Arabia University.png"
import Alhayat from "../assets/images/ourpartners/local/Al Hayaat University.jpg"
import Alimra from "../assets/images/ourpartners/local/Al Imra International University.jpg"
import Almaas from "../assets/images/ourpartners/local/Almaas University.jpg"
import Capital from "../assets/images/ourpartners/local/Capital University.jpeg"
import Central from "../assets/images/ourpartners/local/Central University For Science and Technology.jpg"
import City from "../assets/images/ourpartners/local/City University.jpg"
import Daha from "../assets/images/ourpartners/local/Daha International University.jpg"
import Darul from "../assets/images/ourpartners/local/Darul Hikma University.png"
import Frontier from "../assets/images/ourpartners/local/Frontier University.jpg"
import Gedo from "../assets/images/ourpartners/local/Gedo University.jpg"
import Golden from "../assets/images/ourpartners/local/Golden University.jpg"
import Himilo from "../assets/images/ourpartners/local/Himilo University.jpg"
import Hope from "../assets/images/ourpartners/local/Hope University.jpg"
import hormuud from "../assets/images/ourpartners/local/hormuud.jpg"
import Horseed from "../assets/images/ourpartners/local/Horseed University.png"
import Imamshafi from "../assets/images/ourpartners/local/Imam Shafi i University.jpg"
import IMAM from "../assets/images/ourpartners/local/IMAM University.jpg"
import Islamic from "../assets/images/ourpartners/local/Islamic University.jpg"
import Jamhuriya from "../assets/images/ourpartners/local/Jamhuriya University.jpg"
import Jazeera from "../assets/images/ourpartners/local/Jazeera University.png"
import Jobkey from "../assets/images/ourpartners/local/Jobkey University.jpg"
import Juba from "../assets/images/ourpartners/local/Juba University of Science and Technology.jpg"
import Kowneyn from "../assets/images/ourpartners/local/Kowneyn University.png"
import Modern from "../assets/images/ourpartners/local/Modern University for Science and Technology.jpg"
import Mogadishu_university from "../assets/images/ourpartners/local/Mogadishu-university.png"
import Pan from "../assets/images/ourpartners/local/Pan Africa International University.jpg"
import Plasma from "../assets/images/ourpartners/local/Plasma.png"
import puntlandState from "../assets/images/ourpartners/local/puntlandState.png"
import Salaam from "../assets/images/ourpartners/local/Salaam University.png"
import Simad from "../assets/images/ourpartners/local/Simad University.jpg"
import SIU from "../assets/images/ourpartners/local/SIU.png"
import somaliNationalUniversity from "../assets/images/ourpartners/local/somaliNationalUniversity.jpg"
import Somaville from "../assets/images/ourpartners/local/Somaville University.jpg"
import UNISO from "../assets/images/ourpartners/local/UNISO.jpg"
import ZAMZAM from "../assets/images/ourpartners/local/ZAMZAM University.png"


//accrditations
import wwhts from "../assets/images/ourpartners/accreditations/1.jpg"
import CUFCE from "../assets/images/ourpartners/accreditations/2.jpg"
import ACBSP from "../assets/images/ourpartners/accreditations/3.jpg"
import USHEAC from "../assets/images/ourpartners/accreditations/4.jpg"
import AICE from "../assets/images/ourpartners/accreditations/6.png"






export const up = [
  {id:1, continent: "", list: [
  { id: 1, name: "Hamline University", logo: HM, country: "" },
]},
  { id: 2, name: "Makerere University", logo: MU, country: "" },
  { id: 3, name: "", logo: a_u_w_cover, country: "" },
  { id: 4, name: "", logo: PRiME_Wordmark, country: "" },
  { id: 5, name: "", logo: uom, country: "" },
  { id: 6, name: "", logo: uoj, country: "" },
  { id: 7, name: "", logo: ki, country: "" },
  { id: 8, name: "", logo: usim, country: "" },
  { id: 9, name: "", logo: palermo, country: "" },
  { id: 10, name: "", logo: IUA, country: "" },
  { id: 11, name: "", logo: cust, country: "" },
  { id: 12, name: "", logo: Benha, country: "" },
  { id: 13, name: "", logo: ain, country: "" },
  { id: 14, name: "", logo: mmust, country: "" },
  { id: 15, name: "", logo: bulent, country: "" },
  { id: 16, name: "", logo: cankiri, country: "" },
  { id: 17, name: "", logo: kenyata, country: "" },
  { id: 18, name: "", logo: kazan, country: "" },
  { id: 19, name: "", logo: au_egypt, country: "" },
  { id: 20, name: "", logo: meu, country: "" },
  { id: 21, name: "", logo: ninu, country: "" },
  { id: 22, name: "", logo: saglik, country: "" },
  { id: 23, name: "", logo: umea, country: "" },
  { id: 24, name: "", logo: sust, country: "" },
  { id: 25, name: "", logo: uppsala, country: "" },
  { id: 26, name: "", logo: uqu, country: "" },
  { id: 27, name: "", logo: comsats, country: "" },
  { id: 28, name: "", logo: isuislamabad, country: "" },
  { id: 29, name: "", logo: bahria, country: "" },
  { id: 30, name: "", logo: djabuti, country: "" },
  { id: 31, name: "", logo: JigJiga, country: "" },
  { id: 32, name: "University of Gothenburg", logo: genthernburg, country: "Sweden" },
  { id: 33, name: "", logo: havana, country: "" },
  { id: 34, name: "", logo: Khartoum, country: "" },
  { id: 35, name: "", logo: nairobi, country: "" },
  { id: 36, name: "", logo: arid, country: "" },
  { id: 37, name: "", logo: agakhan, country: "Pakistan" },
  { id: 39, name: "", logo: OMU_logo, country: "Turkey" },
  { id: 38, name: "Istambul Mediniyet Universit",logo: istanbul, country: "Turkey",
  },
];


export const op =[
    {id: 1, name:"", logo:comstech, country: ""},
    {id: 2, name:"", logo:usaid, country: ""},
    {id: 3, name:"", logo:mgfoundation, country: ""},
    {id: 4, name:"", logo:hormuudSalamFoundation, country: ""},
    {id: 5, name:"", logo:shag, country: ""},
    {id: 5, name:"", logo:who, country: ""},
    {id: 6, name:"", logo:iom, country: ""},
    {id: 7, name:"", logo:tika, country: ""},
    {id: 8, name:"", logo:turkish_embassy, country: ""},
    {id: 9, name:"", logo:unicef, country: ""},
]

export const lp =[
  {id: 1, name:"", logo:hormuud},
  {id: 2, name:"", logo:somaliNationalUniversity},
  {id: 3, name:"Mogadishu University", logo:Mogadishu_university},
  {id: 4, name:"Puntland State University", logo:UNISO},
  {id: 6, name:"University", logo:SIU},
  {id: 7, name:"University", logo:ZAMZAM},
  {id: 5, name:"University", logo:Simad},
  {id: 8, name:"University", logo:Somaville},
  {id: 9, name:"University", logo:Salaam},
  {id: 10, name:"University", logo:puntlandState},
  {id: 11, name:"University", logo:Plasma},
  {id: 12, name:"University", logo:Pan},
  {id: 13, name:"University", logo:Modern},
  {id: 14, name:"University", logo:Kowneyn},
  {id: 15, name:"University", logo:Juba},
  {id: 16, name:"University", logo:Jobkey},
  {id: 17, name:"University", logo:Jazeera},
  {id: 18, name:"University", logo:Jamhuriya},
  {id: 19, name:"University", logo:Islamic},
  {id: 20, name:"University", logo:IMAM},
  {id: 21, name:"University", logo:Imamshafi},
  {id: 22, name:"University", logo:Horseed},
  {id: 23, name:"University", logo:Hope},
  {id: 24, name:"University", logo:Himilo},
  {id: 25, name:"University", logo:Abrar},
  {id: 26, name:"University", logo:Aden},
  {id: 27, name:"University", logo:Alarabiya},
  {id: 28, name:"University", logo:Alhayat},
  {id: 29, name:"University", logo:Alimra},
  {id: 30, name:"University", logo:Almaas},
  {id: 31, name:"University", logo:Capital},
  {id: 32, name:"University", logo:Central},
  {id: 33, name:"University", logo:City},
  {id: 34, name:"University", logo:Daha},
  {id: 35, name:"University", logo:Darul},
  {id: 36, name:"University", logo:Frontier},
  {id: 37, name:"University", logo:Gedo},
  {id: 38, name:"University", logo:Golden},
]

export const acc = [
  { id: 1, name: "wazarada waxbarashada iyo hidaha tacliinta sare", logo: wwhts, country: "Somalia" },
  { id: 2, name: "CUFCE", logo: CUFCE, country: "USA" },
  { id: 3, name: "USHEAC", logo: USHEAC, country: "USA" },
  { id: 4, name: "AICE", logo: AICE, country: "USA" },
  { id: 5, name: "ACBSP", logo: ACBSP, country: "USA" },

]

export const all = _(up)
  .concat(op, lp)
  .groupBy("logo")
  .map(_.spread(_.merge))
  .value();

  